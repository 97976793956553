// import React from 'react';
import '../styles/App.css'
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'

// Pages
import HomePage from '../pages/HomePage';
// import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    HomePage()
  );
}

export default App;
